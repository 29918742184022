import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

function QnaList({ qnaList, isLoading, isAdmin, myInfo }: any) {
  //총 4개의 탭으로 구성되어있음. 탭 클릭 시 각각의 컴포넌트로 전환필요.
  // 기본이 notice
  // <faq/>
  // <qna/>
  // <popupReport/>
  const qnaSortList = qnaList.sort((a: any, b: any) => {
    return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
  });
  console.log('qnaSortList', qnaSortList, qnaList);
  return (
    // 공지사항
    <article style={{minHeight:'50vh'}}>
      {isLoading && '로딩중...'}

      <div className="list-wrap">
        <div className="upside-button-area">
          <Link to="/qna/create" className="likebutton">
            문의하기
          </Link>
        </div>

        <ul>
          {!isLoading && qnaList && myInfo && (
            <>
              {!!qnaSortList.length &&
                qnaSortList.map(
                  (qna: any) =>
                    (
                      <li key={qna.qnaId}>
                        <Link to={ (myInfo.userId === qna.userId || myInfo.userId === 8 || myInfo.userId === 9) ? `/qna/${qna.qnaId}` : '#'} style={ myInfo.userId === qna.userId ? {fontWeight: "700"}  :  {fontWeight: "400"} }>
                          <p>{qna.title}</p>
                          <p  >
                            <span className="category">{qna.category}</span>
                            <span className="state state-ok">{qna.isAnswered ? '답변완료' : '답변 대기중'}</span>
                            <span className="inquiry-date">{dayjs(qna.createdAt).format('YYYY-MM-DD')}</span>
                          </p>
                        </Link>
                      </li>
                    ),
                )}
            </>
          )}
        </ul>
      </div>
      <div className="page-area">
        <ul>
          {/* <li>
                <a className="prev-btn"></a>
              </li> */}
          <li>
            <a className="on">1</a>
          </li>
          {/* <li>
                <a>2</a>
              </li>
              <li>
                <a>3</a>
              </li> */}
          {/* <li>
                <a className="next-btn"></a>
              </li> */}
        </ul>
      </div>
    </article>
  );
}

export default QnaList;
