import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../common/Footer';
import { getDocTerms } from '../../lib/contentApiClient';

function Service() {
  const { pathname } = useLocation();
  const [termData, setTermData] = useState<any>({});

  useEffect(() => {
    getDocTerms('popply', 'service').then((res) => {
      setTermData(res?.data?.data?.at(0)?.attributes);
    }).catch((e) => {
      console.log("getPrivacyData", e);
    });        
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <article className="inner site-document">
      <h2>{termData?.title}</h2>
        {termData?.terms_content && 
        <div className="inner site-document"
            dangerouslySetInnerHTML={{ __html: termData?.terms_content }}
          />
        }        
      </article>
      {/* <Footer /> */}
    </>
  );
}

export default Service;
