import Banner from './common/Banner';
import DayCalandar from './common/DayCalandar';
import AddBanner from './common/AddBanner';
import PopupBanner from './common/PopupBanner';
import Footer from './common/Footer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchMainBannerList, getBannerList } from '../lib/api';
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneralDataList } from '../modules/generalData';
import ModalPopup from './common/modal/ModalPopup';
import { set } from 'js-cookie';
import ModalInstagram from './common/modal/ModalInstagram';


function Home({ isApiOpened, isLoading }: any) {  
  const myInfo = useSelector(({auth}: any) => auth.myInfo);     
  const [mainBanner1, setMainBanner1] = useState([]); // 서울
  const [mainBanner2, setMainBanner2] = useState([]); // 오픈예정
  const [mainBanner3, setMainBanner3] = useState([]); // 전시
  const [mainBanner4, setMainBanner4] = useState([]); // 굿즈
  const [mainBanner5, setMainBanner5] = useState([]); // 성수
  const [bannerList, setBannerList] = useState([]);   
  const [popupHtml, setPopupHtml] = useState(null);
  const [instagramLink, setInstagramLink] = useState(null);
  const getMainBannerList = useCallback(myInfo => {
    const innerFunc = async myInfo => {
      try {
        const response = await fetchMainBannerList(myInfo ? myInfo.userId : null);
        const seoul = response.data.data.banner1;
        const scheduled = response.data.data.banner2;
        const expo = response.data.data.banner3;
        const goods = response.data.data.banner4;
        // const seongsu = response.data.data.banner5;
        setMainBanner1(seoul);
        setMainBanner2(scheduled);
        setMainBanner3(expo);
        setMainBanner4(goods);
        // setMainBanner5(seongsu);

        const bannerList = await getBannerList({
          userId: myInfo ? myInfo.userId : null,
        });
        setBannerList(bannerList.data.data);
      } catch (err: any) {
        throw err;
      }
    };
    innerFunc(myInfo);
  }, []);

  useEffect(() => {
    getMainBannerList(myInfo);
    setPopupHtml(eventHtml);
    setInstagramLink(eventInstagramLink);
    return () => {
      setBannerList([]);
    }    
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [count, setCount] = useState(60); // 초기 벚꽃잎 개수
  const handleBodyClick = () => {
    setCount(prevCount => prevCount + 5);
  };

  const articleRef = useRef<HTMLDivElement>(null);

  const todayMonth = dayjs().month() + 1;
  let seasonTit;
  if (3 <= todayMonth && todayMonth <= 5) {
    seasonTit = '봄';
  } else if (6 <= todayMonth && todayMonth <= 8) {
    seasonTit = '여름';
  } else if (9 <= todayMonth && todayMonth <= 11) {
    seasonTit = '가을';
  } else {
    seasonTit = '겨울';
  }

  return (
    <div className="main-page" onClick={handleBodyClick}>
      <article className="main-article" style={{ marginBottom: '-100px' }}>
        <Banner bannerList={bannerList} />
        <article className="article-wrap" ref={articleRef}>
          <PopupBanner storeList={mainBanner1} title={'담당자 픽 서울 인기 팝업'} month={`${todayMonth}월`} type={'seoul'} myInfo={myInfo} />
          <PopupBanner storeList={mainBanner2} title={'놓치면 손해 곧 오픈 예정!'} month={`${todayMonth}월`} type={'scheduled'} myInfo={myInfo} />
          <DayCalandar />
          <AddBanner title={'팝플리에 대해 알고 싶다면?'} content={'궁금한 점이 있으시다면 FAQ나 문의하기를 이용해보세요!'} />
          <article>
            <PopupBanner storeList={mainBanner3} title={`${seasonTit}에 어울리는 전시`} month={`${todayMonth}월`} type={'expo'} myInfo={myInfo} />
            {/* <AddBanner title={'덕질 팝업! 후보'} content={'마루x춘배, 슬램덩크, 포켓몬 '} /> */}
            {/* <AddBanner title={'팝업스토어 어워즈 2023 수상 후보를 미리 만나보세요!'} content={'뷰티,패션,콘텐츠 등등등'} /> */}
            <PopupBanner storeList={mainBanner4} title={'굿즈 만족도 100%'} month={`${todayMonth}월`} type={'goods'}  myInfo={myInfo} />
          </article>
          <Footer />
          {/* <Sakura count={count} parentRef={articleRef} /> */}
        </article>
        <div className="bg"></div>
      </article>
      {popupHtml && 
      <ModalPopup html={popupHtml[0]} link={popupHtml[1]} target={popupHtml[2]} />
      }
      {instagramLink &&
      <ModalInstagram link={instagramLink} />}
    </div>
  );
}

const eventHtml = null;
// const eventHtml = [ 'hello', 'https://biz.popply.co.kr', '_blank' ];
const eventInstagramLink = null;
//const eventInstagramLink = 'https://www.instagram.com/p/C_2Q5BLTuo-/' ;
export default Home;
