import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import './modalInstagram.scss';
import InstagramEmbed from '../InstagramEmbed';

function ModalInstagram({link = ''}: {link: string}) {
  const bn1 = () => {
    window.open(link);
  };
  const VISITED = localStorage.getItem('visited');

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD HH:mm');
    console.log(today);
    if (VISITED && VISITED < today) {
      setOpenLayer(true);
      return;
    } else if (VISITED > today) {
      setOpenLayer(false);
      return;
    } else {
      return;
    }
  }, [VISITED]);

  //레이어 닫기
  const [openLayer, setOpenLayer] = useState(true);
  const layerClick = (isClick: boolean) => {
    setOpenLayer(current => !current);
  };

  const closeTodayPop = () => {
    let expires = dayjs();
    expires = expires.add(1, 'day');
    localStorage.setItem('visited', expires.format('YYYY-MM-DD HH:mm'));
    // 현재 시간의 24시간 뒤의 시간을 homeVisited에 저장
    setOpenLayer(current => !current);
  };

  return (
    <div className={(openLayer ? 'modal-popup' : 'modal-popup hide') + ' instagram-modal'}>
      <div className="main-img" onClick={bn1}>
        <InstagramEmbed url={link} />        
      </div>
      <div className="modal-close">
        <p className="light-btn" onClick={closeTodayPop}>
          오늘 하루 안 보기
        </p>
        <p
          onClick={() => {
            layerClick(false);
          }}
        >
          닫기
        </p>
      </div>
    </div>
  );
}

export default ModalInstagram;
