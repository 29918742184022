import axios from 'axios';
// import { setupCache } from 'axios-cache-interceptor';
console.log(process.env.REACT_APP_CMS_API_URL)

// const contentClient = setupCache(axios.create({
//   baseURL: process.env.NEXT_PUBLIC_CMS_API_URL,
// }
// ),
// {
//   //ttl: 1000 * 5, // 5분 동안 캐시 유지 (밀리초 단위)
//   ttl: 1000 * 5 * 60, // 5분 동안 캐시 유지 (밀리초 단위)
//   methods: ['get'], // GET 요청만 캐시
//   cachePredicate: { statusCheck: (status) => status === 200 }, // 상태 코드가 200일 때만 캐시
// });



const contentClient = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL,
}
);


export const getAuthorizationToken = async () => {
  const token = process.env.REACT_APP_CMS_AUTH_TOKEN;
  //console.log("getAuthorizationToken", token, typeof token)
  return token ?? '';
  //return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token'))?.access_token : '';
};

contentClient.interceptors.request.use(
  async config => {
    // 요청이 전달되기 전에 작업 수행
    if (config.headers['Authorization']) {
      config.headers['X-Authorization'] = config.headers['Authorization'];
    }
    const token = await getAuthorizationToken(); // 비동기로 토큰을 가져옵니다\
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);


// 응답 인터셉터 추가 (선택사항)
contentClient.interceptors.response.use(
  response => {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    return response;
  },
  error => {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    if (error.response.status === 401) {
      // 401 Unauthorized 에러 처리
      // 예: 로그인 페이지로 리다이렉트
    }

    return Promise.reject(error);
  },
);

//---------------- FOR BIZ
// 나중에 캐쉬import { setupCache } from 'axios-cache-interceptor'; 를 통해서 캐슁 필요
export const getOgDataContent = () => contentClient.get('/api/og-meta?populate[0]=PageOgMeta&populate[1]=PageOgMeta.ogImage').catch((e) => {
  console.log("getOgDataContent", e);

  return {} as any
});;
export const getDocTerms = (serviceName: string, termsType: string) => contentClient.get(`/api/doc-terms?populate=*&filters[service_name][$eq]=${serviceName}&filters[terms_type][$eq]=${termsType}`).catch((e) => {
  console.log("getPrivacyData", e);

  return {} as any
});

