import { useState } from 'react';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Divider } from '@material-ui/core';
import dayjs from 'dayjs';
import profileNone from './../../assets/img/member/profile.svg';
import kakaoicon from './../../assets/img/layout/kakao-icon.svg';
import hashicon from './../../assets/img/member/member-icon1.svg';
import reviewicon from './../../assets/img/member/member-icon2.svg';
import StarRate from '../common/StarRate';
import btn_close from './../../assets/img/common/btn_close.svg';
import { deleteUser, duplicateNickname, updateUserNickname } from './../../lib/api';
import GuestAccess from '../auth/GuestAccess';
import client from '../../lib/client';
import Button from '@mui/material/Button';
import storageManager from '../../util/StorageManager';
import { setAccessToken, setMyInfo } from '../../modules/auth';
import { useDispatch } from 'react-redux';
const REDIRECT_HOME = process.env.REACT_APP_REDIRECT_HOME; // PROD
function MemberList({ members, isLoading, comments, onClickCategory, categories }) {
  // 닉네임 수정 팝업
  const [edit, setEdit] = useState(true);
  const [exit, setExit] = useState(false);
  const [quitAgree, setQuitAgree] = useState(false);
  
  const dispatch = useDispatch();
  const editLayerOpen = (isClick: boolean) => {
    setEdit(current => !current);
  };
  const exitLayerOpen = (isClick: boolean) => {
    setExit(current => !current);
  };

  const changeQuitAgree = ()=>{
    setQuitAgree(current=>!current)
  }
  // 닉네임 변경
  const [nickname, setNickname] = useState();
  const changeNickname = e => {
    const { value } = e.target;
    setNickname(value);
  };

  const [nicknamePassed, setNicknamePassed] = useState(null);
  const quit = async()=>{    
    if(!quitAgree) {
      alert('동의해주셔야 탈퇴가 가능합니다.')
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    const isQuit = confirm('정말로 탈퇴하시겠습니까?');
    if(isQuit){
      const result = await deleteUser(members.userId);
      if(result.status===200){        
          delete client.defaults.headers.common.Authorization;
          storageManager.removeValue("token");
          storageManager.removeValue("user");    
          window.location.href = REDIRECT_HOME;
          dispatch(setAccessToken(''));
          dispatch(setMyInfo(null));
        };
      }
    
  }
  const checkNickname = async nickname => {
    const nicknameRegExp = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\-_]{2,10}$/;
    const passRegExp = nicknameRegExp.test(nickname);
    if (passRegExp) {
      const isDuplicated = await duplicateNickname(nickname);
      if (isDuplicated.data.data.length > 0) {
        setNicknamePassed(false);
        return false;
      } else {
        setNicknamePassed(true);
        return true;
      }
    } else {
      setNicknamePassed(false);
      return false;
    }
  };

  const updateNickname = async nickname => {
    const result = await updateUserNickname(members.userId, { user: { nickname: nickname } });
  };
  return (
    <>
      <article className="inner" style={{ paddingBottom: '100px' }}>
        <div>
          {!isLoading && members === null && <GuestAccess />}
          {!isLoading && members !== null && (
            <>
              <div className="submenu">
                <Link to="/member" className="on">
                  <h2>내 정보 </h2>
                </Link>
                <Link to="/user/save-store">
                  <h2>관심팝업 </h2>
                </Link>
                <Link to="/member/reservation/pre">
                  <h2>사전예약 </h2>
                </Link>
                {/* <Link to="/user/visit-store">
                <h2>다녀온 팝업 </h2>
              </Link> */}
                {/* <Link to="/member/reservation/onSite">
                  <h2>현장예약 </h2>
                </Link> */}
              </div>
              <Divider></Divider>
              <div className="profile-img-area">
                <img src={profileNone} alt="빈 프로필" />
              </div>
              <ul className="profile-text-area">
                <li>
                  <span>이름</span>
                  <p>{members.name}</p>
                </li>
                <li>
                  <span>닉네임</span>
                  <p>
                    {members.nickname}
                    <button type="button" className="edit-btn" onClick={() => editLayerOpen(true)}>
                      {members.nickname ? '수정' : '등록'}
                    </button>
                  </p>
                </li>
                <li>
                  <span>계정 정보</span>
                  {/* disabled 처리 됐다면 className에 disabled 추가 */}
                  <p>
                    {members.email}
                    <img src={kakaoicon} alt="카카오아이콘" />
                  </p>
                </li>
                <li>
                  <span>연락처</span>
                  <p>{members.phone ? members.phone : '전화번호를 입력해주세요'}</p>
                </li>
                {/* 우선은 마케팅 동의만, 카톡알림 관련해서는 숨김 처리 */}
                {/* 회원의 경우, 가입할 때 수신동의 체크에 따라 달라져야 함 / 여기서 on 하면 실제로도 db상 마케팅 수신동의에 체크되어야 함 */}
                <li className="switch-wrap">
                  <p>마케팅 수신 동의</p>
                  <p className="switch-line">
                    <label className="switch_btn">
                      {/* 수신동의 체크하면 input에 checked 속성이 붙음 -> 이 값이 저장되어야 함 */}
                      <input type="checkbox" id="m_agree" value="Y" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                </li>
                {/* <li className="switch-wrap">
                  <p>카톡 알림</p>
                  <p className="switch-line">
                    <label className="switch_btn">
                      <input type="checkbox" id="k_agree" value="Y" />
                      <span className="slider round"></span>
                    </label>
                  </p>
                </li> */}
              </ul>
              <div className="member-inner">
                <h3>
                  <p>
                    <img src={hashicon} alt="" /> 관심 카테고리
                  </p>
                </h3>
                <Stack className="category-select" direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
                  {categories.length > 0 &&
                    categories.map((category, idx) => (
                      <Button
                        key={idx}
                        id={category.categoryId}
                        variant="contained"
                        className={category.color === 'default' ? 'default' : 'picked'}
                        onClick={() => {
                          onClickCategory(false, category);
                        }}
                      >
                        {category.name}
                      </Button>
                    ))}
                </Stack>
              </div>

              <div className="member-inner">
                <h3>
                  <p>
                    <img src={reviewicon} alt="" /> 내가 작성한 후기
                  </p>
                  {/* <button type="button">전체보기</button> */}
                  {/* TODO */}
                </h3>

                <div>
                  {comments.length < 1 && (
                    <>
                      <div>등록 된 후기가 없습니다. 후기를 남겨주세요</div>
                      <div className="button-wrap">
                        {/* <button
                      type="button"
                      onClick={() => {
                        // reviewWriteLayerOpen(true);
                        alert('abc)');
                      }}
                    >
                      후기 남기기
                    </button> */}
                      </div>
                    </>
                  )}

                  {comments && !!comments.length && comments.map((comment, idx) => <ReviewItem idx={idx} comment={comment} key={idx} />)}
                </div>
                <div className='likebutton-wrap'>
                  <button type="button" className='likebutton'  
                  onClick={() => exitLayerOpen(true)}>
                    회원탈퇴
                  </button>
                  </div>
              </div>
           
            </>
          )}
        </div>
      </article>

      {/* 닉네임 편집 팝업 */}
      <div className={edit ? 'layer-popup edit-popup' : 'layer-popup shown edit-popup'}>
        <header>
          <p className="layer-tit">
            닉네임 변경
            <button
              type="button"
              onClick={() => {
                editLayerOpen(false);
              }}
            >
              <img src={btn_close} alt="닫기 버튼" />
            </button>
          </p>
        </header>
        <div className="layer-edit-inner">
          <p>닉네임은 10글자 이내로 영어 대소문자, 한글, 숫자와 -, _, .만 사용이 가능합니다.</p>
          <p>중복확인 후 저장하여 변경해주세요.</p>
          <form className="edit-input-wrap">
            <div>
              <div>
                {/* -, _, .외에 입력 시 자동으로 지워지도록 하거나 중복확인 눌렀을 때 허용되지 않는 문자가 있다고 하거나...? */}
                <input type="text" id="change-name" className="edit-input" placeholder="변경할 닉네임을 입력해주세요." maxLength={10} onChange={changeNickname} />
                {/* 중복확인 누르면 alert으로 가능한지 가능하지 않은지 알려줌 */}
                <button type="button" className="likebutton" onClick={() => checkNickname(nickname)}>
                  중복확인
                </button>
              </div>
              {/* 중복확인 후, 사용 가능하다면 show 클래스 추가되어야 함 */}
            </div>
            {nicknamePassed !== null && !!!nicknamePassed && (
              <>
                <span>* 사용 불가능한 닉네임입니다.</span>
              </>
            )}

            {/* 저장 버튼 누르면 마이페이지 내 닉네임 변경, 레이어팝업 닫힘 */}
            {nicknamePassed && (
              <>
                <span>* 사용가능한 닉네임입니다.</span>
                <button
                  type="submit"
                  className="full-btn"
                  onClick={() => {
                    updateNickname(nickname);
                    editLayerOpen(false);
                  }}
                >
                  저장
                </button>
              </>
            )}
          </form>
          <p className="warning">욕설, 비하의 의미가 담긴 닉네임은 설정이 불가합니다.</p>
        </div>
      </div>

      {/* 탈퇴 레이어팝업 */}
      <div className={exit ? 'layer-popup shown layer-exit-popup' : 'layer-popup layer-exit-popup'}>
        <header>
          <p className="layer-tit">
            회원탈퇴
            <button
              type="button"
              onClick={() => exitLayerOpen(false)}
            >
              <img src={btn_close} alt="닫기 버튼" />
            </button>
          </p>
        </header>
        <div className="layer-exit-popup-inner">
          <p>- 회원 탈퇴 시, 회원님의 모든 정보는 팝플리에서 삭제되며, 복구가 불가능하므로 신중하게 결정해 주세요.</p>
          <p>- 팝플리에 작성하신 게시물을 임의로 수정하거나 삭제해드릴 수 없습니다.</p>
          <p>- 진행 중인 사전예약이나 현장대기가 남아있는 경우 해당 사유가 해소된 이후 탈퇴가 가능합니다.</p>
          <p>- 위의 안내를 확인하고 혜택의 소멸, 재가입 시 복구 불가에 동의한다면 확인 버튼을 눌러 탈퇴를 진행해 주세요.</p>
          <label htmlFor="exitCheck"><input type="checkbox" id="exitCheck" required onChange={()=>changeQuitAgree()} />위 사항을 모두 확인하였으며 동의합니다.</label>
          <button type="button" className='likebutton' onClick={()=>quit()} >확인</button>
        </div>
      </div>
    </>
  );

  function ReviewItem(idx: any, comment: any) {
    return (
      <>
        <p className="review-popupname">{/*이쪽에 팝업스토어 이름이 들어오게 해주세요!*/}</p>
        <div className="my-review-wrap">
          <div className="my-review-info">
            <img src={profileNone} alt="기본 프로필" />
            <StarRate starAvg={comment.stars} userName={comment.userName} isDetail={false} />
          </div>
          <div className="my-review-text">
            {comment.comment}
            <p> {getCommentRegisterdTime(comment.createdAt)}</p>
          </div>
        </div>
      </>
    );
  }
}
const getCommentRegisterdTime = registerTime => {
  const today = dayjs();
  const postingDate = dayjs(registerTime);
  const dayDiff = postingDate.diff(today, 'days');
  const hourDiff = postingDate.diff(today, 'hours');
  const minutesDiff = postingDate.diff(today, 'minutes');

  if (dayDiff === 0 && hourDiff === 0) {
    // 작성한지 1시간도 안지났을때
    const minutes = Math.ceil(-minutesDiff);
    return minutes + '분 전'; // '분' ? minutes ago ?
  }

  if (dayDiff === 0 && hourDiff <= 24) {
    // 작성한지 1시간은 넘었지만 하루는 안지났을때,
    const hour = Math.ceil(-hourDiff);
    return hour + '시간 전'; // '시간'으로 표시 ? hours?
  }

  return -dayDiff + '일 전'; // '일'로 표시 ? days
};
export default MemberList;
