import React, { useEffect } from 'react';
const InstagramEmbed = ({ url }: { url: string }) => {
  useEffect(() => {
    // Instagram Embed Script 로드
    const script = document.createElement('script');
    script.async = true;
    script.src = "//www.instagram.com/embed.js";
    document.body.appendChild(script);

    // Instagram Embed 스크립트 실행
    script.onload = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };
    
    // Cleanup 함수로 스크립트를 제거
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return (
    <div>
      {/* Instagram 게시물 Embed 코드 */}
      <blockquote
        className="instagram-media"
        data-instgrm-permalink={url}
        data-instgrm-version="14"
        style={{ maxWidth: '540px', margin: '20px auto' }}
      ></blockquote>
    </div>
  );
};

export default InstagramEmbed;
