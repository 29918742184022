import client from './client';

export const adminSetup = (userId: number, userName: string, userPassword: string) =>
  client.post('/users/setup', {
    userId,
    userName,
    userPw: userPassword,
  });

export const signIn = (userId: string, password: string) => client.post(`/api/authenticate?username=${userId}&password=${password}`);

export const authMe = () => client.get('/api/user/authMe');

export const fetchPromotion = (promotionId: any) => client.get(`/api/promotion/detail/${promotionId}`);
export const fetchPromotionList = () => client.get(`/api/promotion/list`);
export const fetchPromotionWinnerList = (promotionId: any) => client.get(`/api/promotion/${promotionId}/winners`);
export const fetchPromotionQr = (qrUrl: any) => client.get(`/api/promotion/certification?qrUrl=${qrUrl}`);
export const addPromotionComment = (promotionId: any, promotionJoinUser: any) => client.post(`/api/promotion/${promotionId}/comments/`, { promotionJoinUser });

// export const modifyCodeGroup = (groupCode: any, groupName: string) => client.put(`/codegroups/${groupCode}`, { groupName });
// export const writeCodeGroup = (groupCode: any, groupName: string) => client.post(`/codegroups`, { groupCode, groupName });
// export const removeCodeGroup = (groupCode: any) => client.delete(`/codegroups/${groupCode}`);

export const fetchCodeDetail = ({ groupCode, codeValue }: { groupCode: any; codeValue: any }) => client.get(`/codedetails/${groupCode}/${codeValue}`);
export const fetchCodeDetailList = () => client.get(`/codedetails/`);
export const modifyCodeDetail = (groupCode: any, codeValue: any, codeName: any) => client.put(`/codedetails/${groupCode}/${codeValue}`, { codeValue, codeName });
export const writeCodeDetail = (groupCode: any, codeValue: any, codeName: any) => client.post(`/codedetails/`, { groupCode, codeValue, codeName });
export const removeCodeDetail = (groupCode: any, codeValue: any) => client.delete(`/codedetails/${groupCode}/${codeValue}`);

// 그룹코드 목록 획득 서버 API 호출 함수
// export const fetchGroupCodeList = () => client.get('/codes/codeGroup');
// export const fetchJobCodeList = () => client.get('/codes/job');

// 회원가입 API
// export const signUp = (userId: string, userName: string, userPassword: string, job: string) => client.post('/users', { userId, userName, userPw: userPassword });

// 카테고리 목록 조회
export const fetchCategoryList = async () => await client.get('/api/common/category');

// 회원정보
export const fetchMember = (userNo: number) => client.get(`/api/user/${userNo}`);
export const fetchMemberList = () => {
  const res = client.get('/users');
  console.log(res);
};
export const fetchUserFavorites = (userNo: number, payload: any) => client.put(`/api/user/${userNo}/favorites`, payload);
export const updateUserNickname = (userId: number, payload: any) => client.put(`/api/user/${userId}`, payload);
export const deleteUser = (userId: number) => client.delete(`/api/user/${userId}`);

export const fetchCommentList = (userId: number) => client.get(`/api/user/comments`, { headers: { 'x-authorization': userId } });

// export const fetchSaveStoreList = (userNo:number)=> client.get(`/api/user/${userNo}/saveStore`)
export const fetchLikeStoreList = (userNo: number) => client.get(`/api/user/${userNo}/likeStore`);
/* Deprecated */ export const updateLikeStore = (userId: number, store: any) => client.put(`/api/user/${userId}/likeStore`, store);
/* Deprecated */ export const userLikeStore = (userId: number, store: any) => client.put(`/api/user/${userId}/likeStore`, store);
export const userVisitStore = (userId: number, store: any) => client.put(`/api/user/${userId}/visitStore`, store);

// export const fetchVisitStoreList = (userNo:number)=> client.get(`/user/${userNo}/visitStore`)
export const fetchVisitStoreList = (userNo: number) => client.get(`/api/user/${userNo}/visitStore`);

// 게시판
export const fetchBoard = ({ storeId, userId }: { storeId: number; userId: number }) => client.get(`/api/store/${storeId}`, { headers: { 'x-authorization': userId, 'x-source-param': 'fetchBoard' } });
export const fetchBoardByPreview = ({ storeId, previewId }: { storeId: number; previewId: string }) => client.get(`/api/store/preview/${storeId}/${previewId}`);
export const fetchBoardList = ({ userId }) => client.get('/api/store/?from=2022-01-01&to=2024-12-30', { headers: { 'x-authorization': userId } });

export const fetchBoardListMore = (page: number) => client.get(`/api/store/?from=2022-01-01&to=2024-12-30&current=${page}`);
// export const fetchBoardSearchList = ({ searchItem, userId }) => client.get(`/api/store/${searchItem}`, { headers: { 'x-authorization': userId } });
export const fetchBoardSearchList = ({ searchItem, userId }) => client.get(`/api/store/?${searchItem}`, { headers: { 'x-authorization': userId, 'x-source-param': 'boardSearch' } });
export const fetchBoardSearchListAllStatus = ({ searchItem, userId }) => client.get(`/api/store/all/${searchItem}`, { headers: { 'x-authorization': userId, 'x-source-param': 'boardSearchAll' } });

export const fetchListByDate = ({ date, userId }: { date: string; userId: number }) => client.get(`/api/store/list/?date=${date}`, { headers: { 'x-authorization': userId } });
export const fetchMainBannerList = (userId: number) => client.get(`/api/store/banner/list`, { headers: { 'x-authorization': userId } });
export const getBannerList = ({ userId }) => client.post(`/api/store/main/banner/list`, {}, { headers: { 'x-authorization': userId } });
export const fetchSimilarStores = storeId => client.get(`/api/store/${storeId}/similar/list`);
export const fetchBoardView = (storeId: number) => client.put(`/api/store/${storeId}/views`);
export const addComment = (storeId: number, storeDetailComment: any) => client.post(`/api/store/${storeId}/comments`, storeDetailComment);
export const addCommentImage = (storeId, commentId, files) =>
  client.post(`/api/store/${storeId}/comment/${commentId}/upload`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const modifyBoard = (boardNo: number, title: string, content: string) => client.put(`/boards/${boardNo}`, { title, content });
export const writeBoard = (title: string, content: string) => client.post(`/boards/`, { title, content });

export const addStore = store => client.post(`/api/store/`, { store: store });
export const addStoreDev = store => client.post(`http://192.168.20.135:8085/api/store/`, { store: store });

export const addStoreImage = (storeId, files) =>
  client.post(`/api/store/${storeId}/upload`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const addStoreImageDev = (storeId, files) =>
  client.post(`http://192.168.20.135:8085/api/store/${storeId}/upload`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const removeBoard = (boardNo: number) => client.delete(`/boards/${boardNo}`);

// 공지사항
export const fetchMapList = ({ searchItem, userId }) => client.get(`/api/store/${searchItem}`, { headers: { 'x-authorization': userId, 'x-source-param': 'fetchMapList' } });
// export const fetchBoardSearchList = ({ searchItem, userId }) => client.get(`/api/store/${searchItem}`, { headers: { 'x-authorization': userId } });
export const modifyMap = (mapNo: number, title: string, content: string) => client.put(`/notices/${mapNo}`, { title, content });
export const writeMap = (title: string, content: string) => client.post(`/notices/`, { title, content });
export const removeMap = (mapNo: number) => client.delete(`/notices/${mapNo}`);

// 상품
export const fetchItem = (itemId: number) => client.get(`/items/${itemId}`);
export const fetchItemList = ({ userId }: { userId: number }) => client.get(`/api/store/explore`, { headers: { 'x-authorization': userId } });
export const modifyItem = (itemId: number, formData: FormData) =>
  client.put(`/items/${itemId}`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
export const writeItem = (formData: FormData) =>
  client.post(`/items`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
export const removeItem = (itemId: number) => client.delete(`/items/${itemId}`);

// 코인
export const fetchChargeCoinList = () => client.get('/coins');
export const chargeCoin = (amount: number) => client.post(`/coins/charge/${amount}`, { amount });
export const fetchPayCoinList = () => client.get('/coins/pay');

// 상품구매
export const buyItem = (itemId: number) => client.get(`/items/buy/${itemId}`);
export const fetchUserItem = (userItemNo: number) => client.get(`/useritems/${userItemNo}`);
export const fetchUserItemList = () => client.get(`/useritems/`);
export const downloadUserItem = (userItemNo: number) => client.get(`/useritems/download/${userItemNo}`, { responseType: 'blob' });

// 공개자료실
export const fetchPds = (itemId: number) => client.get(`/pds/${itemId}`);
export const fetchPdsList = () => client.get('/pds');
export const modifyPds = (itemId: number, itemObject: any) => client.put(`/pds/${itemId}/`, itemObject);
export const writePds = (itemObject: any) => client.post(`/pds`, itemObject);
export const removePds = (itemId: number) => client.delete(`/pds/${itemId}`);

// 공개자료 첨부파일 업로드 API 호출 함수
export const addAttach = (formData: FormData) =>
  client.post(`/pds/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// 공개자료 첨부파일 목록 조회 API 호출 함수
export const fetchAttachList = (itemId: number) => client.get(`/pds/attach/${itemId}`);

// 서버 동작 확인
export const getServerStatus = () => client.get(`/`);

export const fetchContact = (noticeId: number) => client.get(`/api/contact/notice/${noticeId}`);
export const fetchContactList = () => client.get(`/api/contact/notice`);

export const modifyContact = (itemId: number, itemObject: any) => client.put(`/api/contact/notice/${itemId}/`, itemObject);
export const writeContact = (itemObject: any) => client.post(`/api/contact/notice`, itemObject);
export const removeContact = (itemId: number) => client.delete(`/api/contact/notice/${itemId}`);

export const fetchQna = (itemId: number) => client.get(`/api/contact/qna/${itemId}`);
export const fetchQnaList = () => client.get('/api/contact/qna');
export const modifyQna = (itemId: number, itemObject: any) => client.put(`/api/contact/qna/${itemId}/`, itemObject);
export const writeQna = (qna, userId) => client.post(`/api/contact/qna`, qna, { headers: { 'x-authorization': userId } });
export const answerQna = (data, qnaId,userId) => client.put(`/api/contact/qna/${qnaId}`, { qna: data }, { headers: { 'x-authorization': userId } });
export const removeQna = (itemId: number) => client.delete(`/api/contact/qna/${itemId}`);

export const addBizQna = bizQna => client.post(`/api/contact/bizQna`, bizQna);

export const fetchFaq = (itemId: number) => client.get(`/api/contact/faq/${itemId}`);
export const fetchFaqList = () => client.get('/api/contact/faq');
export const modifyFaq = (itemId: number, itemObject: any) => client.put(`/api/contact/faq/${itemId}/`, itemObject);
export const writeFaq = (itemObject: any) => client.post(`/api/contact/faq`, itemObject);
export const removeFaq = (itemId: number) => client.delete(`/api/contact/faq/${itemId}`);

export const fetchPopupReport = (itemId: number) => client.get(`/api/contact/popupReport/${itemId}`);
export const fetchPopupReportList = () => client.get('/api/contact/popupReport');
export const modifyPopupReport = (itemId: number, itemObject: any) => client.put(`/api/contact/popupReport/${itemId}/`, itemObject);
export const writePopupReport = (itemObject: any) => client.post(`/api/contact/popupReport`, itemObject);
export const addPopupReport = reportPopup => client.post(`/api/contact/report/popup`, { reportPopup: reportPopup });
export const addPopupReportImg = (popupReportId, files) =>
  client.post(`/api/contact/report/popup/${popupReportId}/upload`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const removePopupReport = (itemId: number) => client.delete(`/api/contact/popupReport/${itemId}`);

export const fetchSearchInfo = () => client.get('/api/common/searchInfo');

/**
 * 사전/현장예약
 */

// export const fetchReservations = () => client.get('/api/contact/popupReport');

export const fetchReservation = ({ storeId, userId, type, status }) => client.get(`/api/store/${storeId}/reservation?type=${type}`, { headers: { 'x-authorization': userId } });
export const addUserReservation = (storeId, reservationId, type, userId, reservationInfo) => client.post(`/api/store/${storeId}/reservation/${reservationId}?type=${type}`, { reservationUserInfo: reservationInfo }, { headers: { 'x-authorization': userId } });
// export const fetchListByDate = ({ date, userId }: { date: string; userId: number }) => client.get(`/api/store/list/?date=${date}`, { headers: { 'x-authorization': userId } });
// export const fetchMapList = ({ searchItem, userId }) => client.get(`/api/store/${searchItem}`, { headers: { authorization: userId } });

/**
 * 유저 예약 정보 확인
 */
export const fetchUserReservation = ({ storeId, reservationId, userId, hash }) => client.get(`/api/store/${storeId}/reservation/${reservationId}/${encodeURIComponent(hash)}`, { headers: { 'x-authorization': userId } });
export const fetchUserReservationList = ({ userId, type }) => client.get(`/api/user/reservations?type=${type}`, { headers: { 'x-authorization': userId } });
export const fetchUserReservationByUserId = ({ userReservationId, userId, type }: { userReservationId: number; userId: number; type: string }) => client.get(`/api/user/reservation/${userReservationId}?type=${type}`, { headers: { 'x-authorization': userId } });

export const fetchSurvey = ({ storeId, reservationId, type, userId }) => client.get(`/api/store/${storeId}/reservation/${reservationId}/survey?type=${type}`, { headers: { 'x-authorization': userId } });
// export const addUserSurvey = ({ surveyId, userId }) => client.get(`/api/reservation/survey/${surveyId}`, { headers: { 'x-authorization': userId } });
export const addUserSurvey = ({ reservationId, type, surveyId, surveyUserInfo, userId, hash }) => client.post(`/api/user/reservation/${reservationId}/survey/${surveyId}?type=${type}&hash=${hash}`, { surveyUser: surveyUserInfo }, { headers: { 'x-authorization': userId } });
export const addUserSurveyWithGuest = ({ reservationId, type, surveyId, surveyUserInfo }) => client.post(`/api/user/reservation/${reservationId}/survey/${surveyId}?type=${type}`, { surveyUser: surveyUserInfo });

// 유저 사전설문 참여
export const addPreSurveyUser = ({ userId, reservationId, surveyId, surveyUser }) => client.post(`/api/user/reservation/${reservationId}/survey/${surveyId}`, surveyUser, { headers: { 'x-authorization': userId } });

export const updateUserReservation = ({ userId, userReservation }) => client.put(`/api/user/reservations/status`, { userReservation: userReservation }, { headers: { 'x-authorization': userId } });
//해시로 유저 상태 업데이트
export const updateUserReservation1 = ({ userReservation }) => client.put(`/api/user/reservations/status`, { userReservation: userReservation });
export const interlockhash = hash => client.get(`/api/interlock/reservation/certification?hash=${hash}`);

export const qrUpdate = (storeId, reservationId) => client.get(`/api/interlock/matchQR/${storeId}/${reservationId}`);
export const updateQR = hash => client.get(`/api/interlock/updateQR?hash=${encodeURIComponent(hash)}`);

export const duplicateNickname = nickname => client.get(`/api/user/duplicateNickname?nickname=${nickname}`);

export const fetchPlaceList = ({ userId }) => client.get('/api/store/placelist');
export const addPlaceList = placeListInfo => client.post('/api/store/placelist', { placeList: placeListInfo });

export const addBannerList = bannerList => client.post('/api/store/bannerList', { bannerList: bannerList });

export const fetchGeneralData = ({ dataType, idx }) => client.get(`/api/general/${dataType}?idx=${idx}`);
export const fetchGeneralDataList = ({ dataType, data }) => client.get(`/api/general/${dataType}?${new URLSearchParams(data).toString()}`);
export const createGeneralData = ({ dataType, data }) => client.post(`/api/general/${dataType}`, data);
export const updateGeneralData = ({ dataType, data }) => client.put(`/api/general/${dataType}`, { ...data, method: 'UPDATE' });
export const deleteGeneralData = ({ dataType, data }) => client.put(`/api/general/${dataType}`, { ...data, method: 'DELETE' });
